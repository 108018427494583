// add all images to manifest.js from app/packs/img folder
const img = require.context('../img', true);
const media = require.context('../media', true);

import "./envVariables.js.erb"
import "../icomoon/style"
import "../stylesheets/app"
import 'uppy/dist/uppy.min.css'
import 'tinymce/skins/ui/oxide/skin.css'
import "../css_replacement/replacement"
import "./app"
